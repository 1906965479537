import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import doubleQuoteClosing from '../../assets/images/contact/double-quote-close.svg';
import acciyoLogo from '../../assets/images/contact/testimonials/logos/acciyo.png';
import acciyoClient from '../../assets/images/contact/testimonials/clients/acciyo.png';
import joyfulLogo from '../../assets/images/contact/testimonials/logos/joyful.png';
import joyfulClient from '../../assets/images/contact/testimonials/clients/joyful.png';
import bbbLogo from '../../assets/images/contact/testimonials/logos/bbb.png';
import bbbClient from '../../assets/images/contact/testimonials/clients/bbb.png';
import sticksportsLogo from '../../assets/images/contact/testimonials/logos/sticksports.png';
import sticksportsClient from '../../assets/images/contact/testimonials/clients/sticksports.png';
import embrlabsLogo from '../../assets/images/contact/testimonials/logos/embrlabs.png';
import embrlabsClient from '../../assets/images/contact/testimonials/clients/embrlabs.png';
import lifeprintLogo from '../../assets/images/contact/testimonials/logos/lifeprint.png';
import lifeprintClient from '../../assets/images/contact/testimonials/clients/lifeprint.png';

const CLIENT_TESTIMONIALS = [
  {
    id: 1,
    companyLogo: bbbLogo,
    quote:
      'For all of our projects, Tintash has worked hard to deliver products on time. I’d argue that the quality of their work puts them above what we could find in Silicon Valley.',
    clientName: 'Amra Tareen',
    companyName: 'Bed Bath & Beyond',
    clientDesignation: 'Head of Innovations',
    clientImg: bbbClient,
  },
  // {
  //   id: 2,
  //   companyLogo: acciyoLogo,
  //   quote:
  //     'They built us a project team, handled all communications, and built the product with guidance from our in-house technical lead. The company was so invested their CTO was jumping in to help.',
  //   clientName: 'Anum Hussain',
  //   companyName: 'acciyo',
  //   clientDesignation: 'Co-Founder',
  //   clientImg: acciyoClient,
  // },
  // {
  //   id: 3,
  //   companyLogo: joyfulLogo,
  //   quote:
  //     'Tintash genuinely cared about and took ownership of the project. They don’t approach a task by simply fulfilling the requirements dictated by another person; they try to add value to the work, which I really appreciate.',
  //   clientName: 'Basar Simitci',
  //   companyName: 'Joyful Works',
  //   clientDesignation: 'Owner',
  //   clientImg: joyfulClient,
  // },
  // {
  //   id: 4,
  //   companyLogo: sticksportsLogo,
  //   quote:
  //     'The team excels at general project management, particularly meeting project timelines and understanding particular business needs of their clients.',
  //   clientName: 'Paul Collins',
  //   companyName: 'Stick Sports Ltd.',
  //   clientDesignation: 'CEO',
  //   clientImg: sticksportsClient,
  // },
  // {
  //   id: 5,
  //   companyLogo: embrlabsLogo,
  //   quote:
  //     "I think the most impressive thing is the ratio of professionalism to cost is very high. We feel like we're getting a level of delivery that's more representative of what you would have in the United States.",
  //   clientName: 'David Cohen-Tanugi',
  //   companyName: 'Embr Labs',
  //   clientDesignation: 'Co-Founder',
  //   clientImg: embrlabsClient,
  // },
  // {
  //   id: 6,
  //   companyLogo: lifeprintLogo,
  //   quote:
  //     'They did very well. Great communications, great attention to detail, and are firmly committed to their delivery goals. We had a very positive work experience and a very positive relationship.',
  //   clientName: 'Patrick Cosgrove',
  //   companyName: 'Lifeprint',
  //   clientDesignation: 'CTO',
  //   clientImg: lifeprintClient,
  // },
];

const CLIENT_LOGOS = [
  {
    id: 1,
    logo: require('../../assets/images/contact/client-logos/bed-bath-beyond.png').default,
    alt: 'bed-bath-beyond',
  },
  {
    id: 2,
    logo: require('../../assets/images/contact/client-logos/houghton-mifflin-harcourt.png').default,
    alt: 'houghton-mifflin-harcourt',
  },
  {
    id: 3,
    logo: require('../../assets/images/contact/client-logos/careem.png').default,
    alt: 'careem',
  },
  {
    id: 4,
    logo: require('../../assets/images/contact/client-logos/decorist.png').default,
    alt: 'decorist',
  },
  {
    id: 5,
    logo: require('../../assets/images/contact/client-logos/embr-wave.png').default,
    alt: 'embr-wave',
  },
  {
    id: 6,
    logo: require('../../assets/images/contact/client-logos/lifeprint.png').default,
    alt: 'Life Print',
  },
];

function LogosSidebar() {
  return (
    <aside className="contact-layout__sidebar">
      <div className="contact-sidebar__content">
        <div className="contact-sidebar__content--inner">
          <h2 className="contact__client-testimonials-title">CLIENT TESTIMONIALS</h2>
          <div className="contact__client-testimonials">
            <Carousel
              ssr
              arrows={false}
              infinite
              keyBoardControl={false}
              autoPlay={false}
              // autoPlaySpeed={5000}
              transitionDuration={500}
              customTransition="all 500ms linear"
              responsive={{
                all: {
                  breakpoint: {min: 0, max: Number.MAX_SAFE_INTEGER},
                  items: 1,
                },
              }}
            >
              {CLIENT_TESTIMONIALS.map(testimonial => (
                <div
                  className="contact__client-testimonials-container"
                  key={`testimonial${testimonial.id}`}
                >
                  <div className="contact__client-testimonials-header">
                    <img
                      src={testimonial.companyLogo}
                      alt={`Client Logo ${testimonial.id}`}
                      className="contact__client-testimonials-header-logo"
                    />
                    <div className="contact__client-testimonials-header-line" />
                    <img
                      src={doubleQuoteClosing}
                      alt="Double Quote Closing"
                      className="contact__client-testimonials-header-quote"
                    />
                  </div>
                  <div className="contact__client-testimonials-body">{testimonial.quote}</div>
                  <div className="contact__client-testimonials-footer">
                    <img
                      src={testimonial.clientImg}
                      alt={testimonial.clientName}
                      className="contact__client-testimonials-footer-logo"
                    />
                    <div className="contact__client-testimonials-footer-info">
                      <div className="contact__client-testimonials-footer-info-name">
                        {testimonial.clientName}
                      </div>
                      <div className="contact__client-testimonials-footer-info-designation">
                        <div>{testimonial.clientDesignation},</div>
                        <div>{testimonial.companyName}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
          <hr className="contact-sidebar__hr" />
          <h3 className="contact__client-logos-title">TRUSTED BY</h3>
          <div className="contact__client-logos-container">
            {CLIENT_LOGOS.map(data => (
              <img
                className="contact__client-logo"
                key={`client-logo-${data.id}`}
                src={data.logo}
                alt={data.alt}
              />
            ))}
          </div>
        </div>
      </div>
    </aside>
  );
}

export default LogosSidebar;

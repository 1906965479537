import React, {useState, useCallback, useEffect} from 'react';
import Cookies from 'js-cookie';
import {Helmet} from 'react-helmet';
import '../../assets/css/contact/index.css';
import * as ReactGA from 'react-ga';
import {Link, navigate} from 'gatsby';
import LogosSidebar from '../../components/Contact/LogosSidebar';
import PreloadTintashPaperForm from '../../components/Contact/PreloadTintashPaperForm';
import EmbedContactForm from '../../components/Contact/EmbedContactForm';
import ImageViewerModal from '../../components/Contact/ImageViewerModal/ImageViewerModal';
import useGoogleAnalyticsPageview from '../../hooks/useGoogleAnalyticsPageview';
import CookieBanner from '../../components/Shared/CookieBanner';

export const CONTACT_UTM_SEARCH_PARAM_KEY = 'contact-utm-search-params';
export const PAPERFORM_BUDGET_FIELD_KEY = '10i5j';
export const CONTACT_US_PAPERFROM_ID = 'tintashsignup';

function ContactPage() {
  const [isLowBudget, setIsLowBudget] = React.useState(false);
  const [modalImgURL, setModalImgURL] = React.useState('');
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [showFraudBanner, setShowFraudBanner] = useState(false);

  useGoogleAnalyticsPageview({title: 'Contact.Form'});
  const [, setCookieState] = useState(false);

  const acceptCookieHandler = useCallback(() => {
    Cookies.set('permission-cookies', 'perm-granted', {secure: true}, {expires: 150});
    setCookieState(true);
  }, []);
  React.useEffect(() => {
    window.addEventListener('message', event => {
      if (event.origin !== `https://${CONTACT_US_PAPERFROM_ID}.paperform.co`) {
        // something from an unknown domain, let's ignore it
        return;
      }
      if (event.data === 'click') {
        ReactGA.event({
          category: 'ContactUs',
          action: 'Contact Form Interation',
          label: 'User interacted with the contact form',
        });
      }
      if (event.data && event.data.startsWith('IMG:')) {
        const imgURL = event.data.replace('IMG:', '');
        setModalImgURL(imgURL);
        setIsModalOpen(true);
      }
    });
  }, []);

  const formSubmissionHandler = React.useCallback(e => {
    ReactGA.event({
      category: 'ContactUs',
      action: 'Form 1 Submission',
      label: 'Form 1 Submission',
    });
    e.detail.data.forEach(fieldObj => {
      if (fieldObj.key === PAPERFORM_BUDGET_FIELD_KEY) {
        // Experiment: Comment-out this logic for now
        // if (fieldObj.value === 'No') {
        //   setIsLowBudget(true);
        // } else {
        navigate('/thank-you', {
          state: {
            contactData: e.detail,
          },
        });
        // }
      }
    });
  }, []);
  const getData = async () => {
    fetch('/where-am-i')
      .then(response => response.json())
      .then(data => {
        const {country} = data;
        if (country === 'HK' || country === 'AU') {
          setShowFraudBanner(true);
        }
      });
  };

  useEffect(() => {
    // passing getData method to the lifecycle method
    getData();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us | Tintash</title>
      </Helmet>
      <PreloadTintashPaperForm />
      <ImageViewerModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        imgURL={modalImgURL}
        imgAlt="Tintash's Guide to Budgets and Timelines"
      />
      <div className="contact-layout">
        {!isLowBudget && (
          <div className="contact-layout__content">
            <div>
              <div className="contact-content__logo">
                <img
                  className="img-fluid"
                  src={require('../../assets/images/contact/tintash-logo.png').default}
                  alt="Tintash"
                />
              </div>

              {showFraudBanner ? (
                <div className="contact-content__description-container ">
                  <p
                    className="contact-content__description"
                    style={{backgroundColor: '#C92C35', color: 'white'}}
                  >
                    <i className="fa fa-exclamation-triangle mr-2" aria-hidden="true" />
                    Please beware of scammers pretending to be Tintash. tintash.com and tintash.no
                    are our only websites.&nbsp;
                    <a href="/fraud-disclaimer" rel="noopener noreferrer" style={{color: 'white'}}>
                      <u>Learn more</u>
                    </a>
                  </p>
                </div>
              ) : null}

              <div className="contact-content__description-container">
                <p className="contact-content__description">
                  Let's talk. We'd love to understand what you're trying to build. Please fill out
                  the form below to get started.
                </p>
              </div>
              <div className="contact-content__form">
                <EmbedContactForm
                  paperformId={CONTACT_US_PAPERFROM_ID}
                  searchParamsKey={CONTACT_UTM_SEARCH_PARAM_KEY}
                  paperformSubmissionHandler={formSubmissionHandler}
                />
              </div>
            </div>
          </div>
        )}
        {isLowBudget && (
          <div className="contact-msg-layout__content">
            <div className="contact-msg-layout__content-container">
              <div className="contact-content__logo mb-5">
                <img
                  className="img-fluid"
                  src={require('../../assets/images/contact/tintash-logo.png').default}
                  alt="Tintash"
                />
              </div>
              <h2 className="contact-msg__heading">
                Thank you for your interest in Tintash as a development partner.
              </h2>
              <p className="contact-msg__description">
                Presently, we do not have teams available that suit your required budget. We are
                grateful for your understanding.
              </p>
              <div className="d-flex justify-content-center">
                <Link className="contact-msg__link" to="/">
                  Go to the home page
                </Link>
              </div>
            </div>
          </div>
        )}
        <LogosSidebar />
        {!Cookies.get('permission-cookies') && <CookieBanner acceptHandler={acceptCookieHandler} />}
      </div>
    </>
  );
}

export default ContactPage;

import React from 'react';
import {Helmet} from 'react-helmet';

function PreloadTintashPaperForm() {
  return (
    <Helmet>
      <link rel="preload" href="https://paperform.co/__embed" as="script" />
      <link rel="preconnect" href="https://tintashsignup.paperform.co" />
      {/* WARNING: URLs of these resources might change in future */}
      <link
        rel="preload"
        onLoad="this.rel = 'stylesheet'"
        href="https://duube1y6ojsji.cloudfront.net/_b21a3a6005b7b15a278b.styles.css"
        as="style"
      />
      <link
        rel="preload"
        onLoad="this.rel = 'stylesheet'"
        href="https://fonts.googleapis.com/css?family=Lato:300,400,700|Material+Icons"
        as="style"
      />
      <link
        rel="preload"
        href="https://duube1y6ojsji.cloudfront.net/dbb753ffc57bc5facfdf.form.min.js"
        as="script"
      />
      <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin />
    </Helmet>
  );
}

export default PreloadTintashPaperForm;
